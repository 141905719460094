<template>
  <div class="company-settings" v-loading="loading">

    <h3>Список пользователей, которые не должны получать уведомления</h3>
    Каждый новый пользователь с новой строки.
    Формат строки:
    СНИЛС'комментарий
    <br><br>
    <el-input
      type="textarea"
      :autosize="{ minRows: 4}"
      :value="settings.notifications_users_blacklist"
      @input="updateSettingValue('notifications_users_blacklist', $event)"
      @change="saveSettingValue('notifications_users_blacklist', $event)"
    >
    </el-input>
    <br>
    <br>
    <h3>Процент выполнения на промежуточной оценке, ниже которого предупреждение</h3>
    <el-input
      type="number"
      :value="settings.card_element_forecast_percent_threshold"
      @input="updateSettingValue('card_element_forecast_percent_threshold', $event)"
      @change="saveSettingValue('card_element_forecast_percent_threshold', $event)"
    >
    </el-input>

  </div>
</template>

<script>

import {companySettings} from "@/mixins/companySettings";

export default {
  name: "company-settings",
  mixins: [companySettings],


  props: {},

  watch: {},
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {}
}
</script>


<style lang="scss">

</style>